import React, { useState, useEffect, useRef } from 'react'

import {
  WrapContent,
  MiddleTextContent,
  IntroText,
  GreyBlock,
  H2,
  H3,
  P,
  PUL,
  PMSsustavIMG,
  FullWidthImg,
  ImgTitle,
  MiddleContent,
  GradientText,
  SideText,
  ButtonCTA,
  UnderButtonText,
  LeftColumn,
  TopText,
  LeftLink,
  BlueLine,
  WrapLeftContent,
  SpaceBlock
} from './styles'
import Img from 'gatsby-image'

import Windows from '../../../assets/images/BVRSwindows.png'
import BVRSCM from '../../../assets/images/BVRSchannelManager.png'
import RBE from '../../../assets/images/RBE.png'
import MobileApps from '../../../assets/images/mobileAppsHr.png'
import RentlioIntegracije from '../../../assets/images/HMSintegrations.png'
import RentlioKalendar from '../../../assets/images/BVRSkalendar.png'
import Housekeeping from '../../../assets/images/Housekeeping.png'
import Statistika from '../../../assets/images/Statistika.png'
import Form from '../../AuthorityForm'

const links = [
  {
    id: 1,
    title: 'Što je aplikacija za iznajmljivanje apartmana, vila  i kuća za odmor?'
  },
  {
    id: 2,
    title: 'Ključne značajke i funkcionalnosti aplikacije za iznajmljivanje'
  },
  {
    id: 3,
    title: 'Kako odabrati odgovarajuću aplikaciju za iznajmljivanje?'
  },
  {
    id: 4,
    title: 'Cijena aplikacije za iznajmljivanje, korištenje, onboarding, korisnička podrška'
  }
]

const Content = ({}) => {
  useEffect(() => {
    var links = document.querySelectorAll('.link')
    var sections = document.querySelectorAll('.sections')
    window.onscroll = function () {
      var current = 'home'
      sections.forEach((section) => {
        const sectionTop = section.offsetTop
        if (pageYOffset >= sectionTop - 200) {
          current = section.getAttribute('id')
        }
      })
      links.forEach((item) => {
        item.classList.remove('active')
        if (item.href.slice(-1) === current) {
          item.classList.add('active')
        } else {
          item.classList.remove('active')
        }
      })
    }
  }, [])

  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://rentl.io/property-management-system'
  }
  return (
    <WrapContent>
      <LeftColumn>
        <WrapLeftContent>
          <BlueLine />
          <TopText>Teme unutar stranice</TopText>
          {links.map((link) => (
            <LeftLink key={link.id}>
              <a className="link" href={`#${link.id}`}>
                {link.title}
              </a>
            </LeftLink>
          ))}
        </WrapLeftContent>
      </LeftColumn>
      <MiddleTextContent>
        <H2 id="1" className="sections">
          Što je aplikacija za iznajmljivanje apartmana, vila i kuća za odmor?
        </H2>

        <P>
          Aplikacije za iznajmljivanje koriste vlasnici i osoblje apartmana, hostela, vila i kuća za odmor kako bi
          upravljali cijelim procesom, od najave dolaska gosta do check-outa, i više. U današnjem digitalnom okruženju
          odabir pouzdanog ponuđača softvera koji nudi povezivanje i sinkronizaciju svih rezervacijskih kanala poput
          Booking.com, Airbnb, Expedia i drugih je ključan.
        </P>
        <P>
          <a href="https://rentl.io/mali-iznajmljivaci  ">Cjelovita cloud-based aplikacija za iznajmljivanje</a>{' '}
          pojednostavljuje administrativne zadatke poput check-ina, prijave gostiju i upravljanje cijenama, plaćanjima,
          lokalnim poreznim propisima, statistikom i još mnogo toga.
        </P>
        <P>
          U nastavku pronađite ključne značajke i funkcionalnosti{' '}
          <a href="https://rentl.io/mali-iznajmljivaci">Rentlio aplikacije za iznajmljivanje</a> te kako će vam pomoći
          učinkovitije upravljati apartmanima, vilama ili kućom za odmor.
        </P>
        <FullWidthImg style={{ width: '60%' }}>
          <img src={Windows} />
        </FullWidthImg>
        <GreyBlock>
          <H2 id="2" className="sections">
            Ključne značajke i funkcionalnosti aplikacije za iznajmljivanje
          </H2>
          <P>
            Većina korisnika bira aplikaciju za iznajmljivanje kako bi pojednostavili svakodnevno poslovanje.
            Poznavanjem svih značajki i funkcionalnosti aplikacije za iznajmljivanje učinkovitije ćete upravljati svojim
            apartmanom, kućom za odmor ili vilom, što će dovesti do veće produktivnosti, zadovoljstva gostiju i, na
            kraju, do većeg broja rezervacija i viših prihoda.
          </P>
          <H3>Channel Manager</H3>
          <P>
            Channel Manager usklađuje rezervacije s OTA rezervacijskih kanala poput Booking.com, Airbnb, Expedia i
            drugih prodajnih kanala. I ne samo to, channel manager sinkronizira sve kanale, tako da kada stigne
            rezervacija s Booking.com-a, dostupnost se automatski ažurira na svim drugim kanalima na kojima oglašavate
            svoj smještaj. Nema više brige o overbookingu s integriranim channel managerom i aplikacijom za
            iznajmljivanje.
          </P>
          <P>
            Također, dostupnost i cijene možete ažurirati izravno unutar kalendara. Promjene će biti sinkronizirane i
            vidljive na svim kanalima za nekoliko sekundi.
          </P>
          <P>
            Saznajte više o integriranom{' '}
            <a href="https://rentl.io/channel-manager-hrvatska">Rentlio Channel Manageru.</a>
          </P>
          <FullWidthImg style={{ width: '60%' }}>
            <img src={BVRSCM} />
          </FullWidthImg>
          <ImgTitle>Channel Manager</ImgTitle>
          <H3>Intuitivni kalendar</H3>
          <P>
            Zamislite intuitivan kalendar kao srce Rentlio aplikacije za iznajmljivanje. U kalendaru možete pregledati i
            upravljati rezervacijama, cijenama i dostupnošću za sve smještajne jedinice. Jednostavna drag&select
            značajka omogućuje vam kontrolu nad svim rezervacijama koje su uvijek sinkronizirane na svim OTA kanalima.
          </P>
          <P>
            Više opcija prikaza kalendara dat će vam punih 90 dana pregleda svih jedinica, a pristup aplikaciji putem
            svih uređaja olakšava ažuriranje i kontrolu u bilo koje doba dana.
          </P>
          <H3>Skener osobnih iskaznica i putovnica</H3>
          <P>
            Skener putovnice i osobnih dokumenata dostupan na mobilnoj aplikaciji ubrzava check-in. U nekoliko sekundi
            sve informacije vaših gostiju dostupne su izravno u Rentlio Property Management sustavu.
          </P>
          <P>
            Zaboravite na ručno upisivanje imena, identifikacijskih brojeva i drugih informacija. Uz Rentlio integrirani
            skener osobnih iskaznica i putovnica, uštedjet ćete vrijeme, smanjiti mogućnost pogreške i pojednostaviti
            proces prijave.
          </P>
          <FullWidthImg style={{ width: '60%' }}>
            <img src={RentlioKalendar} />
          </FullWidthImg>
          <ImgTitle>Kalendar</ImgTitle>
          <H3>Mobilna aplikacija</H3>
          <P>
            Rentlio mobilna aplikacija tu je kako biste upravljali svojim poslovanjem putem mobitela. Većina naših
            korisnika gotovo ne koristi svoja prijenosna računala jer mobilna verzija nudi mnoge funkcionalnosti
            dostupne na desktop verziji aplikacije. Ova fleksibilnost omogućuje Rentlio korisnicima upravljanje svojim
            apartmanima, vilama ili kućama za odmor u bilo koje vrijeme, i s bilo kojeg mjesta.
          </P>
          <P>
            <a href="https://rentl.io/mobilna-aplikacija">
              Otkrijte Rentlio mobilnu aplikaciju i naučite kako ju koristiti danas.
            </a>
          </P>
          <FullWidthImg>
            <img src={MobileApps} />
          </FullWidthImg>
          <ImgTitle>Rentlio Mobilna Aplikacija</ImgTitle>
          <H3>Plaćanja</H3>
          <P>
            Rentlijev modul za naplatu olakšava pregled svih plaćanja i otkazivanja rezervacija na jednom mjestu. Za
            svaku rezervaciju možete provjeriti nepodmireni dug i način plaćanja.
          </P>
          <P>
            Također, račune možete ispisivati ili slati putem e-maila. Većina gostiju račun će primiti putem OTA kanala,
            međutim, postoje određeni lokalni propisi prema kojima ste dužni izdati račun. Rentlio nudi mogućnost
            prilagođavanja računa prema vašim željama i potrebama.
          </P>
          <H3>Analitika</H3>
          <P>
            Pouzdanom analitikom pratite tijek prihoda i broj rezervacija iz mjeseca u mjesec. Iskoristite podatke poput
            odakle vaši gosti dolaze, koliko dugo ostaju, koji prodajni kanal najviše koriste kako bi rezervirali i još
            mnogo toga.
          </P>
          <P>
            Statistikom donosite pravovremene strateške odluke i povećavajte cijene sukladno potražnji. I ne samo to,
            udvostručite svoje prodajne i marketinške aktivnosti.
          </P>
          <FullWidthImg style={{ width: '80%' }}>
            <img src={Statistika} />
          </FullWidthImg>
          <ImgTitle>Rentlio Analitika</ImgTitle>
        </GreyBlock>
        {/* <Form locale="hr" /> */}
        <H2 id="3" className="sections">
          Kako odabrati odgovarajuću aplikaciju za iznajmljivanje?
        </H2>
        <P>
          Postoji nekoliko pitanja koja si morate postaviti prije nego što odaberete aplikaciju za iznajmljivanje. U
          nastavku pronađite ključne čimbenike koje biste trebali uzeti u obzir prije odabira aplikacije.
        </P>
        <PUL>
          <ol>
            <li>Aplikacija za iznajmljivanje je cloud-based rješenje?</li>
            <li>Možete li upravljati rezervacijama željenih OTA kanala?</li>
            <li>Imate li mogućnost primanja direktnih rezervacija?</li>
            <li>Omogućuje li vam kalendar upravljanje rezervacijama, cijenama i dostupnošću?</li>
            <li>Dostupan vam je integriran Channel Manager s Property Management sustavom?</li>
            <li>Možete li upravljati poslovanjem pomoću mobitela i mobilne aplikacije?</li>
            <li>Dostupna vam je mogućnost onboardinga 1:1 i pouzdana podrška?</li>
            <li>Koliko vremena je potrebno za početak korištenja aplikacije?</li>
            <li>Postoji li besplatan probni period?</li>
          </ol>
        </PUL>
        <P>Prije odabira prave aplikacije bitno je razumjeti svoje potrebe. </P>
        <P>
          <a href="https://app.rentl.io/registracija">Isprobajte besplatno.</a>
        </P>
        <GreyBlock>
          <H2 id="4" className="sections">
            Cijena aplikacije za iznajmljivanje, korištenje, onboarding, korisnička podrška
          </H2>
          <H3>Cijena</H3>
          <P>
            Cijena aplikacije za iznajmljivanje ovisi o više čimbenika poput broja jedinica, složenosti i broju
            integracija.
          </P>
          <H3>Korištenje</H3>
          <P>
            Kako biste počeli koristiti Rentlio aplikaciju za iznajmljivanje, potrebno vam je samo nekoliko minuta, a na
            raspolaganju imate i besplatan probni period tijekom kojeg možete testirati sve funkcionalnosti koje vas
            zanimaju. Moderne cloud-based aplikacije za iznajmljivanje jednostavne su i s lakoćom ćete postaviti svoje
            objekte te započeti s radom.{' '}
          </P>
          <H3>Onboarding</H3>
          <P>
            Mnogi vlasnici apartmana, vila i kuća za odmor žele biti sigurni u kvalitetno podržan početak rada prije
            početka korištenja aplikacije. Ako odaberete Rentlio, objekt i smještajne jedinice jednostavno možete
            postaviti samostalno, ili uz pomoć jednog od naših stručnjaka za onboarding koji je uz vas, korak po korak.
          </P>{' '}
          <H3>Korisnička podrška</H3>
          <P>
            Korisnička podrška je neophodna i trebala bi biti uključena u vaš cjenovni paket. S prosječnim vremenom
            odgovora manjim od 4 minute, vjerujemo kako je Rentlio korisnička podrška jedna od najboljih s kojima ćete
            imati priliku surađivati.{' '}
          </P>{' '}
          <H3>Ključno prilikom odabira odgovarajuće aplikacije za iznajmljivanje</H3>
          <PUL>
            <ul style={{ listStyleType: 'disc' }}>
              <li>Cijena varira o više čimbenika.</li>
              <li>Početak rada i korištenja trebali bi biti jednostavni i brzi.</li>
              <li>Uvijek tražite cloud-based rješenje.</li>
              <li>Dostupna mogućnost 1:1 onboardinga.</li>
              <li>Saznajte više informacija o kvaliteti korisničke podrške</li>
            </ul>
          </PUL>
        </GreyBlock>
        <H3>Što je Rentlio</H3>
        <P>
          Rentlio je cloud-native aplikacija koja omogućava bezbrižno upravljanje vašim smještajnim kapacitetima.
          Osnovan 2014. u Zadru, Hrvatskoj, od strane stručnjaka i entuzijasta s područja turizma.
        </P>
        <P>
          U Rentliju vjerujemo kako tehnologija treba ići ruku pod ruku s turizmom, a ne suprotno. Rentlio trenutno
          koristi više tisuća korisnika diljem svijeta koji upravljaju s više desetaka tisuća smještajnih jedinica, i
          omogućit će vam fokus na ono što radite najbolje – pružanje iznimnog iskustva svojim gostima.
        </P>
        <P>
          <a href="https://rentl.io/nasa-prica">
            Saznajte više o Rentliju i kako će vam pomoći učinkovitije poslovati.
          </a>
        </P>
        {/* <ButtonCTA onClick={(e) => handleClick(e)}>Saznajte više o Rentlio PMS-u</ButtonCTA>
        <UnderButtonText>Počnite koristiti svoj budući PMS, već danas!</UnderButtonText> */}
        <SpaceBlock />
      </MiddleTextContent>
    </WrapContent>
  )
}

export default Content
