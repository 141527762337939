import styled, { css } from 'styled-components'

export const WrapHMSBanner = styled.div`
  max-width: 1860px;
  width: 100%;
  height: 450px;
  background: linear-gradient(90deg, #0076b5 0%, #0e3152 100%);
  margin-top: 91px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1000px;
  @media only screen and (max-width: 1000px) {
    min-width: 320px;
  }

  @media screen and (max-width: 800px) {
    height: auto;
    padding-top: 95px;
    padding-bottom: 65px;
  }
`
export const HMSContent = styled.div`
  height: auto;
  width: 1250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    object-fit: contain;
  }
  @media only screen and (max-width: 1000px) {
    justify-content: flex-start;

    width: 100%;
    padding-left: 70px;
    padding-right: 30px;
  }

  @media screen and (max-width: 430px) {
    padding-left: 40px;
  }
`
export const BannerImageBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 390px;
  height: 350px;
  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media screen and (max-width: 430px) {
  }
`
export const BannerImage1 = styled.div`
  position: absolute;

  width: 322px;
  height: 176px;
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const BannerImage2 = styled.div`
  position: absolute;
  top: 107px;
  left: 140px;
  width: 322px;
  height: 176px;
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const BannerImage3 = styled.div`
  position: absolute;
  bottom: 15px;
  left: 75px;
  width: 235px;
  height: 54px;
  @media only screen and (max-width: 900px) {
  }

  @media screen and (max-width: 430px) {
  }
`
export const BannerTextBlock = styled.div`
  width: 780px;
  @media only screen and (max-width: 800px) {
    width: 100%;
  }

  @media screen and (max-width: 430px) {
  }
`

export const Title = styled.h1`
  color: #f7f7f7;
  font-family: Barlow;
  font-size: 63px;
  font-style: normal;
  font-weight: 800;
  line-height: 71.96px; /* 114.222% */
  margin-bottom: 30px;
  @media only screen and (max-width: 800px) {
    font-size: 39.2px;
    line-height: 42px; /* 107.143% */
  }

  @media screen and (max-width: 430px) {
  }
`
export const Text = styled.p`
  color: #f7f7f7;
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 141.667% */
  /* width: 60%; */
  @media only screen and (max-width: 800px) {
    font-size: 18px;

    line-height: 24px; /* 133.333% */
  }

  @media screen and (max-width: 430px) {
  }
`
export const Button = styled.button`
  width: 430px;
  border-radius: 20px;
  border: none;
  background: #009fe3;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 14px 30px;
  color: #fff;
  font-family: Barlow;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px; /* 104.545% */
  margin-top: 43px;
  cursor: pointer;
  @media only screen and (max-width: 600px) {
    width: 80%;
    min-width: 276px;
    font-size: 15px;
    line-height: 23px; /* 153.333% */
  }

  @media screen and (max-width: 430px) {
  }
`
