import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

export const WrapHMS = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  box-sizing: border-box;
  max-width: 1860px;
  min-width: 1200px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    min-width: unset;
    width: 100vw;
  }
  @media only screen and (max-width: 425px) {
  }
`

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Header from 'components/Header'
import Footer from 'components/Footer'

import Logo from 'components/Logo'
import Button from 'components/Button'

import NotFoundImg from 'images/not-found-404.png'

import FAQauthority from '../components/hotel-property-management-system/FAQauthority'
import GetStartedCta from '../components/GetStartedCTA'
import Banner from '../components/Best-Vacation-Rental-Software/banner'
import Content from '../components/Best-Vacation-Rental-Software/content'
import schema from '../components/Best-Vacation-Rental-Software/schema.json'
const faqData = [
  {
    content:
      'Aplikacija za iznajmljivanje namijenjena je vlasnicima apartmana, vila i kuća za odmor te property managerima kojom bezbrižno upravljate svojim smještajnim kapacitetima. <a href="https://www.rentl.io">Saznajte više o Rentliju</a> i kako će vam pomoći efikasnije upravljati apartmanima, kućama za odmor i vilama.',
    title: 'Što je aplikacija za iznajmljivanje?'
  },
  {
    content:
      'Iznos pretplate i cijena varira ovisno o broju smještajnih jedinica, jer upravo tako možete biti sigurni kako je cijena točan odraz vaših potreba. <a href="https://rentl.io/cijene">Izračunajte cijenu</a>  korištenja Rentlija za svoj objekt.',
    title: 'Koja je cijena Rentlija?'
  },
  {
    content:
      'Jednostavno. <a href="https://app.rentl.io/registracija">Započnite besplatan probni period </a>i uvjerite se koliko je jednostavno započeti s korištenjem Rentlija.',
    title: 'Kako početi koristiti Rentlio?'
  }
]
const cta_mobile_apps = {
  title: 'Želim isprobati Rentlio!  ?',
  description: 'Kreiraj svoj Rentlio račun, pozovi članove tima i uživaj u bržem, efikasnijem i fokusiranijem radu!'
}

const HPMS = ({ location }) => {
  return (
    <Layout locale="hr">
      <SEO
        title="#1 Aplikacija za iznajmljivanje apartmana, vila i kuća za odmor"
        description="Aplikacije za iznajmljivanje koriste vlasnici i osoblje apartmana, hostela, vila i kuća za odmor kako bi upravljali cijelim procesom, od najave dolaska gosta do check-outa, i više. "
        pathname={location.pathname}
        lang="hr"
        location={location}
        schemaMarkup={schema}
      />
      <Header mode="dark" locale="hr" color={'#FFFFFF'} opacity={0.3} location={location} hasTranslation="false" />
      <WrapHMS>
        <Banner />
        <Content locale="hr" />
      </WrapHMS>
      <FAQauthority questions={faqData} title="FAQ" authority />
      <GetStartedCta data={cta_mobile_apps} locale="en" location={location} />

      <Footer locale="hr" location={location} />
    </Layout>
  )
}

export default HPMS
