import React, { useState, useEffect, useRef } from 'react'

import {
  WrapHMSBanner,
  HMSContent,
  Title,
  Text,
  Button,
  BannerTextBlock,
  BannerImageBlock,
  BannerImage1,
  BannerImage2,
  BannerImage3
} from './styles'
import Img from 'gatsby-image'

import bannerImg1 from '../../../assets/images/BVRSbanner.png'

const Banner = ({}) => {
  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://app.rentl.io/registracija'
  }

  return (
    <WrapHMSBanner>
      <HMSContent>
        <BannerTextBlock>
          <Title>Najbolja aplikacija za iznajmljivanje apartmana, vila i kuća za odmor</Title>
          {/* <Text>
            Hotelski sustav objedinjuje property management sustav (PMS), channel manager, web booking engine, mobilnu
            aplikaciju i integracije koji koriste hotelijeri kako bi upravljali cjelokupnim hotelskim poslovanjem.
          </Text> */}
          <Button onClick={(e) => handleClick(e)}>Isprobajte besplatno </Button>
        </BannerTextBlock>
        <BannerImageBlock>
          <img src={bannerImg1} />
        </BannerImageBlock>
      </HMSContent>
    </WrapHMSBanner>
  )
}

export default Banner
